import React from 'react';
import { Helmet } from 'react-helmet';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { ContentGrid, PrevNextNavi } from '../../components/Project';


import './index.less';
import Layout from '../../components/Layout';


const Project = ({ pageContext, data, ...props }) => {
  const { language: lang } = pageContext;
  const { headerImage: { sizes: headerImage }, images: { edges: images } } = data;


  const { frontmatter: { seoTitle, seoDesc }, body } = data.mdx;
  // const { frontmatter: project, frontmatter: { images: { items: projectImages } } } = props.data.markdownRemark;
  // const getHeader = (originalName) => props.data.images.edges.map((item) => item.node.sizes).filter((item) => originalName.indexOf(item.originalName) !== -1)[0];

  // const getSize = (originalName) => projectImages.filter((item) => originalName.indexOf(item.image) !== -1)[0].size;
  // const getOrder = (originalName) => projectImages.map((item) => item.image).indexOf(originalName);

  // const filteredImages = props.data.images.edges
  //   .map(
  //     (item) => item.node.sizes,
  //   ).filter(
  //     (item) => item.originalName.indexOf(project.imageKey) !== -1 && item.originalName.indexOf('teaser') === -1 && item.originalName.indexOf('header') === -1,
  //   ).map((item) => ({ ...item, size: getSize(item.originalName), order: getOrder(item.originalName) })).sort((a, b) => (a.order < b.order ? -1 : a.order > b.order ? 1 : 0));

  return (
    <Layout {...props}>
      <article className="projectdetail">
        <Helmet
          title={`${seoTitle[lang]}`}
          meta={[
            { name: 'description', content: seoDesc[lang] },
          ]}
        />

        {/* <h1 className="title colorHighlight">{project.titleLong}</h1> */}
        <MDXRenderer
          headerImage={headerImage}
          lang={lang}
          images={images.map(({ node: image }) => image)}
        >
          {body}
        </MDXRenderer>
        {/* <MDXRenderer title="My Stuff!">{mdx.body}</MDXRenderer> */}
        {/* <MDXRenderer>{this.props.data.mdx.body}</MDXRenderer> */}

        {/* {body} */}

        {/* {project.tags.length
       && (
       <div className="tags">
         <ul>
           {
           project.tags.map((elem, index) => <li key={`tag_${index}`} className={`tag_${index}`}>{elem}</li>)
         }
         </ul>
       </div>
       )} */}

        {/* <ContentGrid images={filteredImages} detailRows={project.detailRows} /> */}

        <PrevNextNavi
          prevProjectUrl={pageContext.previousProject.node.frontmatter.seoUrl}
          nextProjectUrl={pageContext.nextProject.node.frontmatter.seoUrl}
          lang={lang}
        />
      </article>
    </Layout>
  );
};


export default Project;

export const projectQuery = graphql`
  query projectBySlugDe($id: String!, $headerImg: String!, $images: [String]) {

    headerImage: imageSharp(sizes: {originalName: {eq: $headerImg}}) {
      id
      sizes(maxWidth: 1600, srcSetBreakpoints: [1110, 950, 460]) {
        originalName
        ...GatsbyImageSharpSizes
      }
    }
    
    images: allImageSharp(filter: {sizes: {originalName: {in: $images}}}) {
      edges {
        node {
          id
          sizes(maxWidth: 950, srcSetBreakpoints: [1110, 950, 460]) {
            originalName
            ...GatsbyImageSharpSizes
          }
        }
      }
    }

    mdx(id: {eq: $id}) {
      frontmatter {
        seoTitle {
          de
          en
        }
        seoDesc {
          de
          en
        }
      }
      body
    }
    
  }
`;
